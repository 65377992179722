const ActaAuthUtils = () => {

    const selectedRoleKey = "acta-selected-role";

    const getAccessToken = () => {
        return localStorage.getItem('accessToken');
    }

    const getRefreshToken = () =>{
        return localStorage.getItem('refreshToken');
    }

    const setToken = (token, type) => {
        if (type === 'access') {
            localStorage.setItem('accessToken', token);
        }
        if (type === 'refresh') {
            localStorage.setItem('refreshToken', token);
        }
    }

    const hasTokens = () => {
        return getAccessToken() != null || getRefreshToken() != null;
    }

    const clearTokens = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    // Login
    const login = async(auth_config, from) => {
        if(from) {
            window.location.replace(auth_config?.frontend_url + "?from=" + encodeURIComponent(from));
        } else {
            if((window.location.href + "/").includes(auth_config?.frontend_url)) {
                window.location.replace(auth_config?.frontend_url);
            } else {
                window.location.replace(auth_config?.frontend_url + "?from=" + encodeURIComponent(window.location.href));
            }
        }        
    }

    // Logout
    const logout = async(auth_config) => {
        window.location.replace(auth_config?.frontend_url + "spid/logout/?from=" + encodeURIComponent(window.location.href));
    }


    const setSelectedRole = (role) => {
        localStorage.setItem(selectedRoleKey, role);
    }
    const getSelectedRole = () => {
        return localStorage.getItem(selectedRoleKey);
    }
    const unsetSelectedRole = () => {
        localStorage.removeItem(selectedRoleKey);
    }

    return {
        getAccessToken,
        getRefreshToken,
        setToken,
        hasTokens,
        clearTokens,

        login,
        logout,


        setSelectedRole,
        getSelectedRole,
        unsetSelectedRole,
    }

}

export default ActaAuthUtils();