import { OfficeClass } from '../../offices/models/office';

export class TagClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
        this.image = data.image;
        this.icon = data.icon;
        this.office = data.office_data !== null ? new OfficeClass(data.office_data) : '';
        this.is_sticky = data.is_sticky;
        this.is_active = data.is_active;
    }
}