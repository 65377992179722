
export const StaffContentSections =  {
    role: "Ruolo",
    contacts: "Contatti",
    documents: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class StaffClass {    
  
    constructor(data){
        this.id = data.id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.slug = data.slug;
        this.role = data.role;
        this.type = data.type;
        this.image = data.image;
        this.content = data.content;
        this.last_update = data.last_update;
    }
}

