import axios from 'axios';
import http from "./http";

class ApiService {

    async getSpidIdps() {
        try{
            let result = await axios.get('https://registry.spid.gov.it/entities-idp?&output=json&custom=info_display_base', {
                timeout: 2000,
            });  
    
            return result.data;
        }catch(e){
            console.log(e);
        }

        try{ 
            let result = await axios.get('/entities-idp.json');  

            return result.data;
        }catch(e){
            console.log(e);
        }


        return [];
    }
}

let apiService = new ApiService();
export default apiService;