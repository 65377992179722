// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { ActaUser } from './apps/user/models/user';
import AuthApiService from 'pa_kit/services/api'; 
import ActaAuthUtils from 'pa_kit/utils';
import RoleSelectionModal from 'pa_kit/components/role_selection_modal';


const ActaAuthContext = createContext();

export default function ActaAuthProvider({ children }) {    

    const [user, setUser] = useState();
    const [organization, setOrganization] = useState({});
    const [theme, setTheme] = useState({});
    const [websiteSettings, setWebsiteSettings] = useState();
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(true);
    const [isWebsiteSettingsLoading, setIsWebsiteSettingsLoading] = useState(true);

    // const fetchUser = async() => {
    //     if(ActaAuthUtils.hasTokens()) {
    //         const data = await ApiService.getUser();
    //         if(data) {
    //             setUser(new ActaUser(data));
    //             setIsUserLoading(false);
    //         }
    //         return data;
    //     } else {
    //         setIsUserLoading(false);
    //         return null;
    //     }
    // }

    const fetchUser = async() => {
        setIsUserLoading(true);
        const data = await AuthApiService.getUser();
        if(data) {
            setUser(new ActaUser(data));
            setIsUserLoading(false);
            return data;
        } else {
            setIsUserLoading(false);
            return null;
        }     
    }

    const fetchWebsiteSettings = async() => {
        const settings = await AuthApiService.getWebsiteSettings();
        setWebsiteSettings(settings); 
        setIsWebsiteSettingsLoading(false);
    }

    const fetchTheme = async(name) => {
        const themeInfo = await AuthApiService.getTheme(name);
        setTheme(themeInfo);
    }

    const fetchOrganization = async() => {
        const organizationInfo = await AuthApiService.getOrganization();
        setOrganization(organizationInfo);
        setIsOrganizationLoading(false);
    }

    const setSelectedRole = (role) => {
        ActaAuthUtils.setSelectedRole(role);
        fetchUser();
      }
    const unsetSelectedRole = () => {
        ActaAuthUtils.unsetSelectedRole();
        fetchUser();
      }
    
    // const fetchCSRFToken = async() => {
    //     await ApiService.csrf_token();
    // }

    useEffect(() => {
        // fetchCSRFToken();
        fetchUser();
        fetchOrganization();
        fetchTheme('default');
        fetchWebsiteSettings();
    }, []);

    const auth = {        
        user,
        organization,
        theme,
        websiteSettings,
        isLoading: isUserLoading || isOrganizationLoading || isWebsiteSettingsLoading,
        setSelectedRole,
        unsetSelectedRole,
    }

    return (
        <ActaAuthContext.Provider value={auth}>
            {children}

            <RoleSelectionModal />
        </ActaAuthContext.Provider>
    );
}

export { ActaAuthContext };