import { Stack } from "@mui/material";
import { Spinner } from "design-react-kit"

const Loading = (props:any) => {

    return(
        <Stack padding={12}  direction="column" gap={2} alignItems="center" width="100%" {...props}>
            <Spinner active />
            {/*
            <div>
                caricamento in corso...
            </div>
            */}
        </Stack>
    );
}
export default Loading;