/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loading from './app/components/loading';

import './css/index.css';
import App from './App';
import './css/styles.css';

import { ActaAuthContext } from "./pa_kit/auth";


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const LoginView = lazy(() => import('./app/login/login'));
    const LoginErrorView = lazy(() => import('./app/login/login_error'));
    const DashboardView = lazy(() => import('./app/dashboard/dashboard'));
    

    if(auth.isLoading) return <Loading />;
    
    return <>
      <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<App />} >
                <Route path="error" element={<LoginErrorView />} />
                <Route path="error/:errorCode" element={<LoginErrorView />} />
                <Route path="" element={<LoginView />} />
              </Route>
            </Routes>
          </Suspense>
      </BrowserRouter>
    </>;
}

export default AppRoutes;

        
        