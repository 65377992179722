
export class GDPRRegistryClass {    
  
    constructor(data){
        this.id = data.id;
        this.ip_address = data.ip_address
        this.source = data.source;
        this.necessary = data.necessary;
        this.statistics = data.statistics;
        this.date = data.date
    }
}