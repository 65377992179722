
import { useContext } from "react";
import { ActaAuthContext } from "pa_kit/auth";
import { Button, Icon, Modal, ModalBody, ModalHeader, Row, Col, Container } from "design-react-kit";
import Loading from 'pa_kit/components/loading';


const RoleSelectionModal = () => {

    const auth = useContext(ActaAuthContext);
    const user = auth.user;

    return (
        <Modal isOpen={user && user.isLoggedIn && user.selectedRole == undefined} size="l" labelledBy='role-select'>
            <ModalHeader id='role-select'>
                Selezione la modalità di utilizzo
            </ModalHeader>
            {auth.isLoading ? (
                <Loading />
            ) : (
                <>
                    <ModalBody>
                        <div>
                            Il tuo account possiede privilegi amministrativi, seleziona la modalità con cui si desidera utilizzale il sito.
                            <Container className='my-4'>
                            <Row></Row>
                            <Row>
                                <Col>
                                    <Button className="d-flex flex-column justify-content-center align-items-center" outline color='primary' style={{ width: "100%", height: 150 }} onClick={() => auth.setSelectedRole("user")}>
                                        <Icon className="d-none d-lg-block"size="xl" color="primary" icon="it-user" /><br/>
                                        UTENTE
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className="d-flex flex-column justify-content-center align-items-center" outline color='primary' style={{ width: "100%", height: 150 }} onClick={() => auth.setSelectedRole(user.role)}>
                                        <Icon className="d-none d-lg-block" size="xl" color="primary" icon="it-pa" /><br/>
                                        OPERATORE
                                    </Button>
                                </Col>
                            </Row>
                            <Row></Row>
                            </Container>
                        </div>
                    </ModalBody>
                </>
            )}
        </Modal>
    );

}

export default RoleSelectionModal;