import axios from 'axios';
import env from '../../env';
import { getCookie } from './manage_cookies';

const authBaseURL = env.AUTH_BACKEND_BASE_URL + 'api/';


export const httpAuthNoHeaders = axios.create({
  baseURL: authBaseURL
});

const httpAuth = axios.create({
    baseURL: authBaseURL,
    headers: {
      "Content-type": "application/json",
      'X-CSRFToken': getCookie('csrftoken')
    },
    withCredentials: true
});

export default httpAuth;