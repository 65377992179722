import { StaffClass } from "../../staff/models/staff";

export const SectorContentSections =  {
    role: "Competenze",
    offices: "Uffici",
    staff: "Componenti",
    contacts: "Contatti",
    services: "Servizi",
    documents: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class SectorClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.content = data.content;
        this.last_update = data.last_update;
        this.staff = data.staff_data?.map((staff) => new StaffClass(staff)) || [];
    }
}