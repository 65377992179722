
export class PageEvaluationClass {
  
    constructor(data){
        this.id = data.id;
        this.page_title = data.page_title;
        this.path = data.path;
        this.rating = data.rating;
        this.feedback_value = data.feedback_value;
        this.feedback_details = data.feedback_details;
        this.cookie_token = data.cookie_token;
        this.ip_address = data.ip_address;
        this.date = data.date;
    }
}